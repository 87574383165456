<template>
  <div class="myFarm weather" v-loading="loading">
    <section class="farmInfo">
      <article v-if="mainFarm">
        <h3>
          <span class="farmerName" v-if="userType == 'EXAMINER'">{{ mainFarm.farmer.username }}</span>
          {{ mainFarm.farmName }}
        </h3>
        <p>
          <span class="material-icons">location_on</span>
          {{ mainFarm.address }}
        </p>
        <div class="hvstInfo flexB box" v-if="maxValue">
          <div>
            <p>최대 수확 시점</p>
            <h3>{{ moment(this.category[maxDate]).format("M월 D일") }}</h3>
          </div>
          <div>
            <p>예상 수확량</p>
            <h3>{{ numberFormat(Math.round(maxValue)) }}kg</h3>
          </div>
        </div>
      </article>
      <article v-else>
        <p class="nodata">농장을 등록해 주세요.</p>
      </article>
    </section>

    <section class="chart" v-if="mainFarm">
      <article class="box">
        <p class="bold">생산량 예측 그래프</p>
        <HarvestChart v-if="category.length > 0" :chartData="chartData" :category="category" />
        <div v-else class="nodata">데이터가 없습니다.</div>
      </article>
    </section>

    <section class="chart iframe" v-if="mainFarm">
      <p class="bold">
        온도 Trend
        <span class="flr">(℃)</span>
      </p>
      <div>
        <article class="trendChart" v-if="deviceTrendKey && deviceTrendKey.tempKey">
          <div class="iframeWrap" style="position: absolute; top: -370px; left: -20px">
            <iframe :src="`https://s2.radionode365.com/link/viewer_basic.html?key=${deviceTrendKey.tempKey}`" width="100%" height="905" scrolling="no" frameborder="0"></iframe>
          </div>
        </article>
        <article v-else class="nodata box">데이터가 없습니다.</article>
      </div>
    </section>

    <section class="chart iframe" v-if="mainFarm">
      <p class="bold">
        습도 Trend
        <span class="flr">(%)</span>
      </p>

      <div>
        <article class="trendChart" v-if="deviceTrendKey && deviceTrendKey.humKey">
          <div class="iframeWrap" style="position: absolute; top: -370px; left: -20px">
            <iframe :src="`https://s2.radionode365.com/link/viewer_basic.html?key=${deviceTrendKey.humKey}`" width="100%" height="905" scrolling="no" frameborder="0"></iframe>
          </div>
        </article>
        <article v-else class="nodata box">데이터가 없습니다.</article>
      </div>
    </section>

    <section class="chart iframe" v-if="mainFarm">
      <p class="bold">
        CO2 Trend
        <span class="flr">(%)</span>
      </p>
      <div>
        <article class="trendChart" v-if="deviceTrendKey && deviceTrendKey.humKey">
          <div class="iframeWrap" style="position: absolute; top: -370px; left: -20px">
            <iframe :src="`https://s2.radionode365.com/link/viewer_basic.html?key=${deviceTrendKey.co2Key}`" width="100%" height="905" scrolling="no" frameborder="0"></iframe>
          </div>
        </article>
        <article v-else class="nodata box">데이터가 없습니다.</article>
      </div>
    </section>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { mapState } from "vuex";
import { fetchModel } from "../api/growth";
import HarvestChart from "../components/contents/HarvestChart.vue";
import moment from "moment";

export default {
  components: { HarvestChart },
  mixins: [format],

  data() {
    return {
      moment: moment,
      loading: false,
      maxValue: "",
      maxDate: "",
      chartData: [],
      category: [],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 3);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "수확량 예상");
  },
  computed: {
    ...mapState(["mainFarm", "deviceTrendKey", "userType", "growthList"]),
  },

  mounted() {
    this.getModel();
  },
  methods: {
    getModel() {
      let farm = this.mainFarm._id;
      fetchModel(farm).then((res) => {
        // console.log("fetchModel", res);
        if (res.data.status == 200 && res.data.data[0]) {
          this.chartData = res.data.data[0].pred;
          this.category = res.data.data[0].date;
          this.maxValue = Math.max.apply(null, this.chartData);
          this.maxDate = this.chartData.indexOf(this.maxValue);
        }
      });
    },
  },
};
</script>
